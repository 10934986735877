<template>
    <div>
        <button class="btn btn-header btn-light w-100">
            <i class="far fa-window-restore mr-2"></i>
            <span>Header</span>
        </button>
        <div class="component-content">
            <div class="page-title">
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>